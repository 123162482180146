<script>
import axios from "axios";
import chatList from "@/components/chat/chatList.vue";
import {getFirebaseBackend} from "../../helpers/firebase/authUtils";

export default {
    name: "chatButton",
    components: {chatList},
    data() {
        return {
            url: 'http://127.0.0.1:5000/',
            chats: {},
            users: {},
            userChat : null,
            show: false,
            showList: false,
            showListLoad: false
        };
    },
    computed: {},
    mounted() {
        this.openUser();
    },
    methods: {
        openUser() {
            const user = JSON.parse(localStorage.getItem('auth.currentUser'));
            const obj = {
                user_primary_identifier: user.uid,
                user_primary_name: user.displayName ?? user.email,
                user_primary_email: user.email,
                user_primary_telephone: '00',
                user_primary_timezone: 'Sao_Paulo'
            }

            axios.post(this.url + `v1/user`, obj)
                .then(response => {
                    this.userChat = response.data.data;
                    this.loadChat();
                })
                .catch(error => {
                    console.log(error);
                });
        },
        openChat(item) {
            const user = JSON.parse(localStorage.getItem('auth.currentUser'));
            const obj = {
                user_primary_identifier: user.uid,
                user_primary_name: user.displayName ?? user.email,
                user_primary_email: user.email,
                user_primary_telephone: '00',
                user_primary_timezone: 'Sao_Paulo',
                user_secondary_name: item.displayName ?? item.email,
                user_secondary_email: item.email,
                user_secondary_identifier: item.uid,
                user_secondary_telephone: '00',
                user_secondary_timezone: 'Sao_Paulo',
                chat_name: item.displayName ?? item.email,
                chat_description: 'Chat privado',
                chat_type: 'private',
            }

            const idUserTo = item.uid;


            this.showListLoad = true;
            axios.post(this.url + `v1/chat`, obj)
                .then(response => {
                    console.log(response.data);
                    axios.get(this.url + `v1/chat/${user.uid}`)
                        .then(response => {
                            this.showListLoad = false;
                            this.chats = response.data;
                            this.chats.data.forEach(item => {
                                item.show = (item.chats_user_to.identifier == idUserTo) ? true : false;
                            })
                        })
                        .catch(error => {
                            this.showListLoad = false;
                            console.log(error);
                        });
                })
                .catch(error => {
                    this.showListLoad = false;
                    console.log(error);
                });

        },
        async loadChat(idOpen = false) {

            const user = JSON.parse(localStorage.getItem('auth.currentUser'));
            const token = await getFirebaseBackend().getIDToken();

            axios.get('https://us-central1-gestio-school-dev.cloudfunctions.net/core_usuarios/api/index', {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then((response) => {
                this.users = response.data.users;
                this.users.forEach(item => {
                    item.show = false;
                    item.id = item.uid
                })
            }).catch((error) => {
                console.log('error', error)
            });


            this.showList = this.showList ? false : true;

            if (this.showList == false) {
                return;
            }

            this.show = true;
            axios.get(this.url + `v1/chat/${user.uid}`)
                .then(response => {
                    this.show = false;
                    this.chats = response.data;
                    this.chats.data.forEach(item => {
                        item.show = (idOpen && item.id == idOpen) ? true : false;
                    })
                })
                .catch(error => {
                    this.show = false;
                    console.log(error);
                });
        }
    }
};
</script>

<template>
    <div class="button-chat">
        <b-overlay :show="showListLoad" rounded="sm">
            <chatList v-if="showList" :chats="chats" :users="users" :userChat="userChat" @openChat="openChat"/>
        </b-overlay>
        <b-overlay :show="show" rounded="sm">
            <div class="buttonChat">
                <button @click="loadChat" type="button" class="btn btn-primary">
                    <span class="pendente">0</span>
                    chat
                </button>
            </div>
        </b-overlay>
    </div>
</template>

<style lang="scss">
.button-chat {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 99;
    background: #fff;
    width: 80px;
    height: 80px;
    border-radius: 100px;
    box-shadow: 0 0 4px #ccc;

    > div > div,
    button {
        display: block;
        width: 80px;
        border-radius: 100px;
        box-shadow: none;
        font-size: 24px;
        cursor: pointer;
    }

    .buttonChat > button {
        height: 80px;
    }

    .b-overlay {
        .rounded-sm {
            border-radius: 100px !important;
        }
    }

    .pendente {
        color: #fff;
        background: red;
        width: 20px;
        height: 20px;
        display: block;
        border-radius: 30px;
        font-size: 12px;
        line-height: 20px;
        position: absolute;
        right: -4px;
        top: 4px;
    }
}
</style>