<script>
import chatMessage from "@/components/chat/chatMessage.vue";

export default {
    name: "chatList",
    components: {chatMessage},
    data() {
        return {
            userLogin: {},
        }
    },
    props: {
        chats: {
            type: Object,
            required: true
        },
        users: {
            required: true
        },
        userChat : {
            required: true
        }
    },
    emits: ['openChat'],
    mounted() {
        this.userLogin = JSON.parse(localStorage.getItem('auth.currentUser'));
    },
    methods: {
        openChat(item) {
            this.$emit('openChat', item);
        },
        showChat(item) {
            this.chats.data.forEach((chat, index) => {
                if (chat.id === item.id) {
                    this.chats.data[index].show = this.chats.data[index].show ? false : true;
                }
            });
            this.$forceUpdate();
        },
        checkUserInChat(item) {
            try {
                let check = true;
                this.chats.data.forEach(user => {
                    if (user.chats_user_to.identifier === item.uid) {
                        check = false;
                    }
                });
                return check;
            } catch (e) {
                console.log(e);
                return false;
            }
        },
        getChatName(item){
            if (item.id_user == this.userChat.id) {
                return  item.chats_user_to.name;
            } else if (item.id_user_to == this.userChat.id) {
                return  item.chats_user.name;
            }
        }
    }
}
</script>

<template>
    <div v-if="chats.data" class="chat-list">
        <h3>{{ userLogin.displayName ?? userLogin.email }}</h3>
        <input type="text" placeholder="Busca..." class="form-control">
        <ul class="listscroll">
            <li><strong>-- Chats ativos --</strong></li>
            <li v-for="item in chats.data" :key="item.id" @click="showChat(item)">
                {{ getChatName(item) }}
            </li>
            <li><strong>-- Usúarios --</strong></li>
            <template v-for="item in users">
                <li :key="item.id" @click="openChat(item)" v-if="checkUserInChat(item)">
                    {{ item.displayName ?? item.email }}
                </li>
            </template>
        </ul>

        <div class="form-chats">
            <template v-for="item in chats.data">
                <chatMessage :key="item.id" :chat="item" :userChat="userChat" v-if="item.show"/>
            </template>
        </div>

    </div>
</template>

<style scoped lang="scss">
.chat-list {
    color: black;
    position: absolute;
    width: 300px;
    height: 400px;
    bottom: -80px;
    right: 91px;
    background: #ffff;
    box-shadow: 0 0 4px #ccc;
    border-radius: 10px;
    padding: 10px;
    cursor: default;

    h3 {
        font-size: 20px;
        border-bottom: 1px solid #f1f1f1;
        padding-bottom: 10px;
        margin-bottom: 10px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            margin: 0;
            font-size: 14px;
            padding: 10px;

            & + li {
                border-top: 1px dashed #ccc;
            }

            &:hover {
                background: #f1f1f1;
                cursor: pointer;
                border-radius: 10px;
            }
        }
    }

    .form-chats {
        position: absolute;
        bottom: 0;
        right: 106%;
        display: flex;
    }
}

.listscroll {
    overflow-y: scroll;
    height: 300px;
}


</style>