<script>
import {layoutComputed} from "@/state/helpers";
import Vertical from "./vertical";
import Horizontal from "./horizontal";
import chatButton from "@/components/chat/chatButton.vue";

export default {
    components: {Vertical, Horizontal, chatButton},
    data() {
        return {};
    },
    computed: {
        ...layoutComputed
    },
    methods: {}
};
</script>

<template>
    <div>
        <vertical v-if="layoutType === 'vertical'" :layout="layoutType">
            <slot/>
        </vertical>

        <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
            <slot/>
        </Horizontal>

        <chatButton />
    </div>
</template>
