<script>
import io from 'socket.io-client';
import axios from "axios";
import moment from "moment";

export default {
    name: "chatMessage",
    data() {
        return {
            chatName: '',
            msg: '',
            messages: {
                data: []
            },
            socket: null,
            chatCor: 1,
            isSendMenssage: false
        }
    },
    props: {
        chat: {
            type: Object,
            required: true
        },
        userChat: {
            required: true
        }
    },
    mounted() {
        console.log('this.userChat', this.userChat)
        console.log('this.chat', this.chat)
        if (this.chat.id_user == this.userChat.id) {
            this.chatName = this.chat.chats_user_to.name;
        } else if (this.chat.id_user_to == this.userChat.id) {
            this.chatName = this.chat.chats_user.name;
        }
        this.downScroll()
        this.startChat();
        this.chatCor = this.sortNumber();
    },
    methods: {
        sortNumber() {
            return Math.floor(Math.random() * 10);
        },
        downScroll() {
            console.log('downScroll')
            this.$refs.bodyMensagens.scrollTop = this.$refs.bodyMensagens.scrollHeight;

            setTimeout(() => {
                this.$refs.bodyMensagens.scrollTop = this.$refs.bodyMensagens.scrollHeight;
            }, 1000);
            setTimeout(() => {
                this.$refs.bodyMensagens.scrollTop = this.$refs.bodyMensagens.scrollHeight;
            }, 500);
            setTimeout(() => {
                this.$refs.bodyMensagens.scrollTop = this.$refs.bodyMensagens.scrollHeight;
            }, 100);
        },
        pingChat() {
            this.socket.emit('message', {ping: 'ping', chat: this.chat.id, id: this.chat.id});
        },
        startChat() {

            const vueThis = this;

            this.socket = io('http://127.0.0.1:5000/');
            this.pingChat();

            // setInterval(() => {
            //     vueThis.pingChat();
            // }, 5000);

            this.socket.on('message', (data) => {
                if (!data.ping)
                    this.messages.data.push(data);

                this.isSendMenssage = false;

                vueThis.downScroll()
            });
            this.loadMenssages();
        },
        loadMenssages() {
            axios.get(`http://127.0.0.1:5000/v1/chat/${this.chat.id}`).then((response) => {
                this.messages = JSON.parse(JSON.stringify(response.data));
                console.log('this.messages', this.messages)
                this.downScroll()
            }).catch((error) => {
                console.log(error);
            });
        },
        sendMessage() {
            if (this.msg.trim() === '') return;
            this.isSendMenssage = true;
            const obj = JSON.parse(JSON.stringify(this.chat))
            obj.message = this.msg
            obj.id_user = this.userChat.id
            this.socket.emit('message', {
                ...obj,
                created_at_locale: moment().format('YYYY-MM-DD HH:mm:ss')
            });
            this.msg = '';
        },
        dateFormat(date) {
            const datex = moment(date).format('DD/MM/YYYY HH')
            return datex + 'h' + moment(date).format('mm');
        }
    }
}
</script>

<template>
    <div class="modal-chat" v-if="chat.show">
        <div class="header">
            <h3>{{ chatName }}</h3>
        </div>
        <div :class=" 'body cor-' + chatCor  " ref="bodyMensagens">

            <div v-if="messages && messages.data">
                <div v-for="msg in messages.data" :key="msg.id" :class=" 'msg-item '+ (msg.id_user == userChat.id ? 'minha':'') + ' user-'+userChat.id  + ' user-chat-'+msg.id_user ">
                    <span>
                        {{ msg.message }}
                        <i>{{ dateFormat(msg.created_at) }}</i>
                    </span>
                </div>
            </div>

        </div>
        <div class="footer">
            <div class="row">
                <div class="col-9">
                    <b-overlay :show="isSendMenssage">
                        <input @keyup.enter="sendMessage" type="text" v-model="msg" placeholder="Mensagem" class="form-control">
                    </b-overlay>
                </div>
                <div class="col-3">
                    <b-overlay :show="isSendMenssage">
                        <button @click="sendMessage" class="btn btn-success"><i class="fa fa-paper-plane"></i></button>
                    </b-overlay>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.modal-chat {

    h3 {
        display: block;
        font-size: 18px;
        border-bottom: 1px solid #f1f1f1;
        padding: 10px;
        margin-bottom: 10px;
        background: #4aa3ff;
        color: #fff;
        border-radius: 8px 8px 0 0;
    }


    display: inline-block;
    width: 288px;
    height: 400px;
    background: #fff;
    border: 2px solid #ccc;
    margin-left: 10px;
    border-radius: 10px;
    padding: 10px;
    position: relative;

    .header,
    .footer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        right: 0;
    }

    .body {
        height: 282px;
        overflow: auto;
        margin-top: 44px;
    }

    .footer {
        bottom: 0;
        top: initial;
        padding: 10px;

        button {
            padding: 0;
            width: 100%;
            display: block;
        }
    }
}

.msg-item > span {
    font-size: 15px;
    padding: 5px 10px;
    border-radius: 10px;
    background: #4aa3ff;
    color: #fff;
    display: inline-block;
    clear: both;
    margin-right: 40px;
    margin-bottom: 25px;
    line-height: 120%;
    position: relative;

    i {
        border-radius: 10px;
        font-size: 10px;
        position: absolute;
        width: 100px;
        text-align: left;
        bottom: -20px;
        color: #ccc;
        left: 0;
    }
}

.msg-item.minha {
    text-align: right;

    span {
        margin-left: 40px;
        margin-right: 0;
        background: #1cbb8c;
        color: #fff;

        i {
            text-align: right;
            right: 0;
            left: inherit;
        }
    }
}


.cor-1 .msg-item.minha span {
    background-color: #f3410e;
}

.cor-2 .msg-item.minha span {
    background-color: #f18905;
}

.cor-3 .msg-item.minha span {
    background-color: #eabb07;
}

.cor-4 .msg-item.minha span {
    background-color: #b8c403;
}

.cor-5 .msg-item.minha span {
    background-color: #62a404;
}

.cor-6 .msg-item.minha span {
    background-color: #18bb06;
}

.cor-7 .msg-item.minha span {
    background-color: #04912c;
}

.cor-8 .msg-item.minha span {
    background-color: #04a179;
}

.cor-9 .msg-item.minha span {
    background-color: #0159b2;
}

.cor-10 .msg-item.minha span {
    background-color: #0115d0;
}


</style>